import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import './HomeProduct.css'
import { ButtonView } from '../components/Buttons/Buttons'
const HomeProductsOne= () => {
    const navigate = useNavigate();
    const handlenavigate = () => {
        navigate('/products');
    }
  return (
    <>
    <div className='product_background bg-overlay padding z'>
        <Container>
            <Row>
                <Col lg="12" xs="12" md="12">
                    <div className='text-center z text-white'>
                        <h2 className='bold'> Our Products</h2>
                        <p className='regular'>With over 200 varieties of crackers developed and marketed every year, we are among the most sought brands in the Sivakasi region and around the country. Our products are known for their safety and we take great efforts to ensure that all our orders are delivered in a standard time frame with an economical pricing.
                        </p>
                    </div>
                </Col>
                <Col lg='3' md="4" xs='12' className='my-3'>
                    <div className='home-product-box'>
                        <div className='p-4'>
                            <div className='hm-product text-center'>
                                <img src={require('../assets/images/singlesound.webp')} className='img-fluid w-75' alt='single sound'/>
                            </div>
                        </div>
                        <div class="text-center pb-3">
                             <p class="regular h4">SingleSound</p>
                        </div>
                    </div>
                </Col>
                <Col lg='3' md="4" xs='12' className='my-3'>
                    <div className='home-product-box'>
                        <div className='p-4'>
                            <div className='hm-product text-center'>
                                <img src={require('../assets/images/garland.webp')} className='img-fluid w-75' alt='single sound'/>
                            </div>
                        </div>
                        <div class="text-center pb-3">
                             <p class="regular h4">Garland</p>
                        </div>
                    </div>
                </Col>
                <Col lg='3' md="4" xs='12' className='my-3'>
                    <div className='home-product-box'>
                        <div className='p-4'>
                            <div className='hm-product text-center'>
                                <img src={require('../assets/images/sparklers.webp')} className='img-fluid w-75' alt='single sound'/>
                            </div>
                        </div>
                        <div class="text-center pb-3">
                             <p class="regular h4">Sparklers</p>
                        </div>
                    </div>
                </Col>
                <Col lg='3' md="4" xs='12' className='my-3'>
                    <div className='home-product-box'>
                        <div className='p-4'>
                            <div className='hm-product text-center'>
                                <img src={require('../assets/images/rockets.webp')} className='img-fluid w-75' alt='single sound'/>
                            </div>
                        </div>
                        <div class="text-center pb-3">
                             <p class="regular h4">Rockets</p>
                        </div>
                    </div>
                </Col>
                <Col lg='3' md="4" xs='12' className='my-3'>
                    <div className='home-product-box'>
                        <div className='p-4'>
                            <div className='hm-product text-center'>
                                <img src={require('../assets/images/flowerpots.webp')} className='img-fluid w-75' alt='single sound'/>
                            </div>
                        </div>
                        <div class="text-center pb-3">
                             <p class="regular h4">Flower Pots</p>
                        </div>
                    </div>
                </Col>
                <Col lg='3' md="4" xs='12' className='my-3'>
                    <div className='home-product-box'>
                        <div className='p-4'>
                            <div className='hm-product text-center'>
                                <img src={require('../assets/images/chakkar.webp')} className='img-fluid w-75' alt='single sound'/>
                            </div>
                        </div>
                        <div class="text-center pb-3">
                             <p class="regular h4">
                             Chakkars</p>
                        </div>
                    </div>
                </Col>
                <Col lg='3' md="4" xs='12' className='my-3'>
                    <div className='home-product-box'>
                        <div className='p-4'>
                            <div className='hm-product text-center'>
                                <img src={require('../assets/images/fancyfountain.webp')} className='img-fluid w-75' alt='single sound'/>
                            </div>
                        </div>
                        <div class="text-center pb-3">
                             <p class="regular h4">Fancy Fountains</p>
                        </div>
                    </div>
                </Col>
                <Col lg='3' md="4" xs='12' className='my-3'>
                    <div className='home-product-box'>
                        <div className='p-4'>
                            <div className='hm-product text-center'>
                                <img src={require('../assets/images/skyshots.png')} className='img-fluid w-75' alt='single sound'/>
                            </div>
                        </div>
                        <div class="text-center pb-3">
                             <p class="regular h4">Sky Shots</p>
                        </div>
                    </div>
                </Col>
                <Col xs="12">
                    <div className='z text-center py-3'>
                        <ButtonView label={<>Shop Now</>} className="shop-now" onClick={handlenavigate}/>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
       
    </>
  )
}

export {HomeProductsOne}